/* =======chat_lftpnl-------start--===== */
.chat_conversations {
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}
.chat_pnlOuter {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
}
.chat_lftpnl {
  width: 20%;
  background-color: #f8f8f8;
}
.chat_rightpnl {
  width: 80%;
  position: relative;
}
.left_topPnl {
  padding: 15px 20px 15px;
}
.profile_usrOuter,
.profile_rfresh_outer {
  display: flex;
  align-items: center;
}
.profile_usrOuter {
  justify-content: space-between;
}
.rfresh_profilePic a {
  display: block;
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 50%;
  overflow: hidden;
}
.rfresh_icon a {
  display: block;
  line-height: 100%;
  color: #37aab2;
}
.rfresh_icon a span {
  font-size: 18px;
}
.rfresh_icon {
  margin-right: 10px;
}
.profile_row {
  display: flex;
  /* align-items: center; */
}
.profile_pic a {
  display: block;
  line-height: 100%;
}
.profile_bar a {
  color: #5f6368;
  display: block;
  margin-right: 10px;
  line-height: 100%;
}
.profile_bar a i {
  font-size: 30px;
}
.usr_srchRow {
  margin: 10px 0 0;
}
.usr_srchRow .form-control {
  height: 50px;
  box-shadow: none;
  border: 1px solid #e4e4e4;
  font-size: 16px;
  color: #5f6368;
  border-radius: 8px;
  padding: 0 15px 0 42px;
  background-image: url(../../ConversationAssests/svg/chat_srch.svg);
  background-position: left 13px top 50%;
  background-repeat: no-repeat;
}

.chat_usrRow a {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
}
.chat_usrRow a:hover {
  background-color: #f0f0f0;
}
.usr_lftPnl {
  display: flex;
  align-items: center;
}
.chatting_user {
  background: linear-gradient(180deg, #ebebeb 0%, #dadada 100%);
  height: 42px;
  width: 42px;
  min-width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  color: #c1c1c1;
}
.user_name {
  padding-left: 10px;
}
.user_name h3 {
  font-size: 18px;
  color: #004254;
  font-weight: 500;
}
.user_name p {
  color: #6f7a7c;
  font-size: 14px;
}
.user_name p i {
  vertical-align: middle;
  font-size: 16px;
}
.msg_statusRight span {
  display: block;
}
.msg_time {
  font-size: 12px;
  color: #5f6368;
  font-weight: 500;
}
.msg_status i {
  font-size: 16px;
  color: #5f6368;
}
.msg_status {
  text-align: right;
}
.typing_text {
  color: #37aab2;
  font-weight: 500;
  font-size: 13px;
}
.seen_tick i {
  color: #37aab2 !important;
}
.msg_statusRight {
  text-align: right;
}
.pending_msg {
  height: 18px;
  width: 18px;
  justify-content: center;
  display: flex !important;
  align-items: center;
  font-size: 10px;
  color: #fff;
  background-color: #37aab2;
  border-radius: 50%;
  text-align: center;
  float: right;
}
.group_msg_row .chatting_user {
  background: transparent;
  flex-wrap: wrap;
  overflow: visible;
}
.group_usr {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}
.group_usr img {
  width: 100%;
  height: 100%;
}
.plus_usr {
  font-size: 10px;
  color: #fff;
  background-color: #37aab2;
}
.chat_usrPnl {
  height: calc(100vh - 127px);
  position: relative;
}
.chat_innrScroll {
  height: 100%;
  overflow-y: auto;
}
.mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.mCSB_scrollTools {
  opacity: 0 !important;
}

.chat_cmntBx {
  position: absolute;
  right: 20px;
  bottom: 23px;
  z-index: 99;
}
.chat_btn {
  background: #37aab2;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  z-index: 9;
}
.chat_btn:hover {
  color: #fff;
}
.lft_cht_popup {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 70px;
  min-width: 165px;
  padding: 10px 0;
  transition: 0.5s;
  z-index: 2;
  transform: scale(0);
}
.lft_cht_popup li a {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  font-size: 16px;
  color: #5f6368;
}
.lft_cht_popup li a i {
  font-size: 21px;
  margin-right: 6px;
}
.lft_cht_popup li a:hover {
  background-color: #f0f0f0;
}
.show_chatpopup .lft_cht_popup {
  transform: scale(1);
}
.active_user a {
  background-color: #ddd;
}

/* =======chat_lftpnl-------end--===== */

/* chat_rightpnl=========start */
.pd_top_60 {
  padding-top: 60px;
}
.chatusr_info {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%);
}
.chat_usrOuter {
  display: flex;
  align-items: center;
}
.chat_usrPic {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 42px;
}
.chat_usrtext {
  padding-left: 10px;
}
.chat_usrtext h3 {
  font-size: 16px;
  color: #004254;
  font-weight: 700;
  line-height: 28px;
}
.chat_usrtext h5 {
  font-size: 14px;
  color: #6f7a7c;
}
.info_icons ul {
  display: flex;
  align-items: center;
}
.info_icons ul li + li {
  margin-left: 15px;
}
.info_icons ul li a {
  color: #5f6368;
}
.chat_tablinks {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%);
  padding: 15px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
}
.chat_tablinks ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat_tablinks ul li {
  position: relative;
  min-width: 170px;
  text-align: center;
}
.chat_tablinks ul li a {
  font-size: 16px;
  color: #5f6368;
  font-weight: 500;
  text-align: center;
}
.chat_tablinks ul li a i {
  vertical-align: bottom;
  font-size: 20px;
  color: #5f6368;
}
.chat_tablinks ul li:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -15px;
  height: 3px;
  background-color: transparent;
  border-radius: 5px 5px 0 0;
  transform: scale(0);
  transition: 0.5s;
}
.chat_tablinks ul li.active a {
  color: #2f3337;
}
.chat_tablinks ul li.active a i {
  color: #37aab2;
}
.chat_tablinks ul li.active:after {
  transform: scale(1);
  background-color: #37aab2;
}

.chat_sendPnl {
  background: #ffffff;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  display: flex;
  align-items: center;
}
.emoji_iconArea ul {
  display: flex;
  align-items: center;
  min-width: 85px;
}
.emoji_iconArea ul li + li {
  margin-left: 6px;
}
.emoji_iconArea ul li a {
  color: #5f6368;
  display: block;
  line-height: 100%;
}
.emoji_iconArea ul li a span {
  font-size: 20px;
}
.send_textbx {
  width: 100%;
  position: relative;
}
.send_textbx .form-control {
  height: 50px;
  font-size: 16px;
  color: #5f6368;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  box-shadow: none;
  padding-right: 40px;
}
.chat_send_btn {
  cursor: pointer;
  background-color: transparent;
  color: #37aab2;
  font-size: 30px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}
.nothing_pic {
  text-align: center;
}
.nothing_pic figure {
  max-width: 300px;
  margin: 0 auto 20px;
}
.chatting_innr.chating_start {
  padding: 30px 0;
}
.chating_mdlPnl {
  height: calc(100vh - 165px);
  position: relative;
}
/* .chat_mdlInnr{
    height: calc(100vh - 294px);
} */
.chatting_innr {
  height: 100%;
  overflow-y: auto;
}
.chating_start {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .chating_start .mCustomScrollBox{
    display: flex;
    align-items: center;
    justify-content: center;
} */
.nothing_caption p {
  font-size: 14px;
  color: #6f7a7c;
}
.tags_row {
  padding: 15px 20px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
  display: flex;
  align-items: center;
}
.tags_links_items ul {
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.tags_links_items {
  max-width: 80%;
}
.tags_row ul li a {
  background: #e4e4e4;
  border-radius: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  font-size: 16px;
  color: #000;
  height: 44px;
}
.tags_row ul li a:hover,
.tags_row ul li.selected a {
  background: linear-gradient(180deg, #ffa564 0%, #ff6464 100%);
  color: #fff;
}
.tags_row ul li + li {
  margin-left: 5px;
}
.tags_row .dropdown-menu.show {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  min-width: 450px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.tags_row .dropdown-menu li {
  margin-bottom: 10px;
}
.expand_arw {
  margin-left: 10px;
}
.msg_outer {
  padding: 15px 40px 30px;
}
.msg_row {
  display: flex;
  margin-bottom: 40px;
}
.msg_row:last-child {
  margin-bottom: 0;
}
.chat_infoLft {
  min-width: 150px;
  display: flex;
}
.msg_usr {
  height: 25px;
  width: 25px;
  min-width: 25px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.chat_info_timeOuter h4 i {
  vertical-align: middle;
  font-size: 20px;
}
.chat_info_timeOuter h4 {
  font-size: 16px;
  color: #5f6368;
  font-weight: 500;
  margin-bottom: 5px;
}
.chat_info_timeOuter h5 {
  font-size: 12px;
  color: #5f6368;
}
.chat_info_timeOuter h5 span {
  vertical-align: middle;
}
.chat_msgright {
  width: 100%;
  padding-left: 30px;
}
.msg_text {
  background-color: #f4f4f4;
  /* border-radius: 20px; */
  padding: 10px 15px;
  margin-bottom: 10px;
  display: inline-block;
}
.msg_text p {
  font-size: 16px;
  color: #2f3337;
  line-height: 26px;
}
.upload_picOuter {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3px;
  max-width: 100%;
}
.upload_pic_item {
  width: auto;
  padding: 3px;
  position: relative;
  display: inline-block;
}
.xls-section,
.pdf-section {
  max-width: 60px;
}
.upload_viewimg {
  max-width: 190px;
}
.upload_pic_item figure img {
  width: 100%;
  border-radius: 20px;
}
.media_caption {
  position: absolute;
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.media_caption a {
  font-size: 20px;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mail_text {
  border: 1px solid #e4e4e4;
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 20px;
  display: inline-block;
}
.mail_text h4 {
  font-size: 16px;
  color: #2f3337;
  font-weight: 500;
  margin-bottom: 10px;
}
.mail_text a {
  display: inline-block;
  font-size: 14px;
  color: #2f3337;
  line-height: 20px;
}
.mail_text a i {
  vertical-align: middle;
  background: linear-gradient(180deg, #ffa564 0%, #ff6464 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}
.own_msgRow {
  justify-content: flex-end;
}
.own_msgRow .chat_infoLft {
  order: 2;
}
.own_msgRow .chat_msgright {
  padding-left: 0;
  padding-right: 30px;
  order: 1;
  text-align: right;
  max-width: 71%;
}
.own_msgArea {
  margin-bottom: 10px;
  background: #37aab2;
  padding: 10px 15px;
  display: inline-block;
}
.own_msgArea:last-child {
  margin-bottom: 0;
}
.own_msgArea p {
  font-size: 16px;
  color: #fff;
}

/* chat_rightpnl=========end */

/* group-chat--------start */
.list_back {
  display: none;
}
.grp_chat_Innr {
  height: calc(100vh - 165px);
  position: relative;
}
.msg_shareicon_info > ul {
  display: flex;
  align-items: center;
}
.msg_shareicon_info > ul > li {
  margin-right: 15px;
}
.msg_shareicon_info > ul > li:last-child {
  margin-right: 0;
}
.msg_shareicon_info > ul > li > a {
  color: #929aa8;
  font-size: 16px;
}
.msg_shareicon_info > ul > li > a span {
  color: #2f3337;
}
.msg_shareicon_info > ul > li > a i {
  font-size: 21px;
  vertical-align: middle;
}
.msg_shareicon_info > ul > li:first-child a i {
  font-size: 18px;
}
.msg_shareicon_info > ul > li.active a i {
  vertical-align: middle;
  color: #37aab2;
}
.msg_shareicon_info .dropdown-menu li a {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  font-size: 16px;
  color: #5f6368;
}
.msg_shareicon_info .dropdown-menu li a i {
  font-size: 20px;
  margin-right: 10px;
}
.msg_shareicon_info .dropdown-menu {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.msg_shareicon_info .dropdown-menu li a:hover {
  background-color: #f0f0f0;
}
.grp_usrname_time span {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}
.grp_usr_prsn {
  color: #2f3337;
}
.grp_usrTime {
  color: #5f6368;
}
.grp_usrname_time {
  margin-bottom: 10px;
}
.group_usrmsg_row .msg_usr {
  margin: 0;
}
.group_usrmsg_row .chat_msgright {
  padding-left: 15px;
}
.group_usrmsg_row .chat_infoLft {
  min-width: auto;
}
.grp_ownmsg .chat_info_timeOuter {
  margin-bottom: 10px;
}

.chat_usrOuter .chatting_user {
  background: transparent;
  flex-wrap: wrap;
  overflow: visible;
}

/* ========toggle-switch========== */
.chat_profileinfo_pnl,
.hamberger_menuPnl {
  width: 20%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(20px);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  transition: 0.4s;
  transform: translateX(-100%);
}
.hamberger_menuPnl {
  background: #003442;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.chat_profileinfo_pnl .pro_back_btn {
  display: flex;
  justify-content: flex-end;
  padding: 15px 10px 0;
}
.profile_open .chat_profileinfo_pnl,
.hamberger_menuOpen .hamberger_menuPnl {
  transform: translateX(0);
}
.pro_back_btn {
  padding: 30px 20px 0;
}
.profile_infoOuter,
.hamberger_menuInnr {
  height: calc(100vh - 75px);
  overflow-y: auto;
}
.hamberger_menuInnr {
  padding-top: 10px;
}

.pro_back_btn a {
  height: 44px;
  width: 44px;
  background-color: #e4e4e4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.profile_picPnl {
  text-align: left;
  display: flex;
  align-items: flex-start;
  position: relative;
}
.profile_info_row {
  padding: 20px;
  border-bottom: 1px solid #e4e4e4;
}
/* .profile_info_row:first-child{
    padding: 0 20px 20px;
} */
.profile_img {
  height: 60px;
  width: 60px;
  display: flex;
  background-color: #e4e4e4;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0;
  position: relative;
}
.profile_img figure {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
}
.profile_upload {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -7px;
}
.profile_upload a {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  height: 22px;
  width: 22px;
  min-width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #5f6368;
}
.profile_upload a i {
  font-size: 14px;
}
.pic_upload {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.profile_textPnl {
  position: relative;
  padding-left: 15px;
}
.email_editicon {
  position: absolute;
  right: 0px;
  top: 10px;
}
.editable_fill.email_editable_fill .form-control {
  text-align: center;
}
.profile_textPnl h4 {
  font-size: 16px;
  color: #004254;
  font-weight: 700;
  line-height: 28px;
}
.profile_textPnl h5 {
  font-size: 14px;
  color: #5f6368;
}
.profile_iocnedit_outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info_icon {
  display: flex;
  align-items: center;
}
.info_icon i,
.pro_edit_btn i {
  vertical-align: middle;
  color: #37aab2;
  font-size: 20px;
  margin-right: 10px;
}
.pro_edit_btn i {
  margin: 0;
}
.info_icon span {
  font-size: 16px;
  color: #004254;
  font-weight: 700;
}
.profile_details_row p,
.profile_details_row p a {
  font-size: 15px;
  color: #2f3337;
  margin-top: 10px;
  display: inline-block;
}
.profile_details_row p a {
  margin: 0;
}
.profile_details_row p a:hover {
  color: #37aab2;
}
.editable_fill {
  display: none;
}
.editfill_open .editable_fill {
  display: block;
  margin-top: 10px;
}
.editfill_open .protxt_con {
  display: none;
}
.editfill_open .profile_textPnl h5 {
  display: none;
}
.editable_fill .form-control {
  box-shadow: none;
  color: #2f3337;
  font-size: 15px;
  outline: none;
  border: 0;
  border: 1px solid #ccc;
  height: 44px;
  border-radius: 0;
}
.mute_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

input[type="checkbox"].switch_1 {
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 31px;
  height: 18px;
  background: #37aab2;
  border-radius: 8.5px;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* input[type="checkbox"].switch_1:checked{
	background: #0ebeff;
  }
   */
input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  top: -0.3px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1.5em);
}

/* ========toggle-switch========== */

.signout_fill .info_icon span {
  color: #004254;
}
.signout_fill .info_icon i {
  color: #004254;
  background: none;
  -webkit-text-fill-color: inherit;
}
.profile_pnlArea {
  width: 330px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  transition: 0.4s;
  background-color: #f8f8f8;
  transform: translateX(350px);
}
.profile_menuPnl_open .profile_pnlArea {
  transform: translateX(0px);
}
/* .profile_pnlArea:after{
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    height: 142px;
    background-color: #fff;
    z-index: -1;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
} */
.profile_hdngTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.profile_hdngTop h3 {
  font-size: 18px;
  color: #2f3337;
  font-weight: 500;
}
.profile_crossbtn {
  background-color: #f8f8f8;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.profile_crossbtn i {
  color: #5f6368;
  font-size: 22px;
}
.profile_pnlArea .profile_img {
  height: 111px;
  width: 111px;
  margin-bottom: 10px;
}
.profile_innr_body {
  height: calc(100vh - 80px);
}
.profile_innrScroll {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 25px;
}
.profile_attachedment_outer {
  display: flex;
  flex-wrap: wrap;
  margin: 15px -5px 0;
}
.attachedment_item {
  width: 33.33%;
  padding: 5px;
}
.attachedment_item a {
  display: block;
}
.attachedment_item a img {
  border-radius: 7px;
}

.added_text h5 {
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 20px;
}
.added_text p {
  font-size: 14px;
  color: #004254;
  margin-bottom: 10px;
}

/* ========================================= */

/* =====strat---modal--css */
.mdl_hdng {
  border: 0;
}
.mdl_hdng h5 {
  font-size: 18px;
  color: #2f3337;
  font-weight: 500;
  margin: 0;
}
.mdl_hdng .close {
  background-color: transparent;
  color: #929aa8;
}
.mdl_hdng .close span {
  font-size: 22px;
}
.chat_modalbx .modal-body {
  padding: 0;
}
.group_srchfill {
  padding: 0 16px 10px;
}
.group_srchfill .form-control {
  height: 50px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 0 15px 0 40px;
  background-image: url(../../ConversationAssests/svg/chat_srch.svg);
  background-position: left 10px top 50%;
  background-repeat: no-repeat;
  box-shadow: none;
  font-size: 16px;
  color: #5f6368;
}
.chat_modalbx .modal-content {
  background: #f8f8f8;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 10px 150px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}
.chat_modalbx .chat_usrRow a:hover {
  background-color: transparent;
}
.chat_modalbx .chat_usrRow {
  border-bottom: 1px solid #e4e4e4;
}
.chat_modalbx .modal-dialog {
  max-width: 460px;
}
.chat_modalbx .chat_usrRow a {
  align-items: center;
}
.members_check span {
  height: 16px;
  width: 16px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
  position: relative;
  display: block;
}
.members_check span:after {
  height: 8px;
  width: 8px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ornge_bullet span {
  background: #37aab2;
}
.group_innrScroll {
  max-height: 350px;
  overflow-y: auto;
}
.chat_modalbx .chat_usrRow a {
  padding: 12px 16px;
}
.group_btnOuter {
  padding: 16px;
}
.group_btnOuter ul {
  display: flex;
  margin: 0 -5px;
}
.group_btnOuter ul li {
  padding: 0 5px;
}
.w_50 {
  width: 50%;
}
.w_100 {
  width: 100%;
}
.group_btnOuter ul li a {
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  color: #004254;
}
.blue_btn {
  background-color: #37aab2;
  color: #fff !important;
}
.blue_btn i {
  color: #fff !important;
}
.ornage_btn {
  background: #fbbb21;
}
.group_btnOuter ul li a i {
  margin-right: 8px;
  color: #004254;
}
.blue_bullet span {
  background-color: #37aab2;
}
.enter_group_name {
  width: 100%;
}
.enter_group_name .form-control {
  height: 36px;
  box-shadow: none;
  font-size: 18px;
  color: #6f7a7c;
  border: 0;
  background-color: transparent;
  font-weight: 300;
  padding: 0;
}
.enter_group_name .form-control::placeholder {
  color: #b9bdc5;
}
.tag_links {
  padding: 16px 16px 0;
}
.tag_links ul {
  display: flex;
  flex-wrap: wrap;
}

.tag_links ul li {
  margin: 0 5px 5px 0;
}
.tag_links ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-radius: 0px;
  font-size: 14px;
  color: #004254;
  padding: 0 20px;
  background-color: #f4f4f4;
  border: 1px solid #6f7a7c;
}
.tag_links ul li a:hover {
  background: #37aab2;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: #fff;
  border-color: #37aab2;
}
.add_fill .form-control {
  background-image: none;
  padding: 0 40px 0 15px;
}
.add_fill {
  position: relative;
}
.add_icon {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  color: #929aa8;
}
.add_icon:hover {
  color: #37aab2;
}

.login_fillOuter {
  padding: 0 20px;
}
.chat_input_fill .form-control {
  height: 50px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 0 15px;
  box-shadow: none;
  font-size: 16px;
  color: #5f6368;
}
.chat_input_fill {
  margin-bottom: 15px;
}
.chat_input_fill:last-child {
  margin-bottom: 0;
}
.uloaded_viewModal .modal-dialog {
  max-width: 100%;
  margin: 0 auto;
  background-color: #000;
}
.uloaded_viewModal .modal {
  background-color: rgba(0, 0, 0, 0.09);
}
.uloaded_viewModal .modal-content {
  background-color: #003442;
  border: 0;
}
.upload_innrscroll,
.upload_footerInnr {
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 15px;
}
.chat_uploadItem {
  background: #00242e;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}
.chat_upload_pic {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.chat_upload_pic img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}
.chat_upload_caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat_upload_caption p {
  font-size: 16px;
  font-weight: 500;
  color: #929aa8;
  width: 83%;
}
.chat_upload_caption p span {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  width: 100%;
}
.chat_upload_caption a {
  color: #929aa8;
}
.chat_upload_caption a i {
  font-size: 18px;
}
.upload_footerArea {
  background: #004254;
  backdrop-filter: blur(10px);
  padding: 25px 15px;
}
.upload_input_msg .form-control {
  height: 50px;
  background: #f4f4f4;
  border: 1px solid #003442;
  border-radius: 8px;
  box-shadow: none;
  font-size: 16px;
  color: #5f6368;
}
.upload_input_msg {
  margin-bottom: 20px;
}
.addsend_btnOuter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addsend_btnOuter button + button {
  margin-left: 10px;
}
.upload_addBtn,
.upload_sendBtn {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  position: relative;
}
.upload_addBtn {
  background: #003442;
}
.upload_sendBtn {
  background: #37aab2;
}
.upload_addBtn i,
.upload_sendBtn i {
  color: #fff;
  font-size: 20px;
}
.upload_fileBtn {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.upload_innrscroll {
  height: calc(100vh - 230.2px);
  overflow-y: auto;
}

.upload_innrBody .row {
  justify-content: center;
}
.uloaded_viewModal .mdl_hdng .close span {
  font-size: 30px;
  color: #fff;
}
.uloaded_viewModal .mdl_hdng .close {
  margin-right: 10px;
}
.uloaded_viewModal .modal-header {
  padding: 12px 0;
}
/* end--modal--css */

.email_poupPnl {
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  width: 800px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 99;
  transform: translateX(100%);
  transition: 0.5s;
}
.emailpopup_open .email_popup_view {
  transform: translateX(0);
}
.reply_openPopup .email_msg_rply_View {
  transform: translateX(0);
  z-index: 100;
}
.email_topPnl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
}
.subject_fill {
  width: 100%;
}
.emailuser_outer {
  display: flex;
  align-items: center;
}
.email_uploadBtn {
  position: relative;
}
.email_cross a {
  height: 45px;
  width: 45px;
  min-width: 45px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2f3337;
}
.email_cross {
  margin-right: 15px;
}
.email_userInfo h3 {
  font-size: 16px;
  color: #004254;
  font-weight: 700;
}
.email_userInfo a {
  font-size: 14px;
  color: #6f7a7c;
}
.subject_fill {
  margin-top: 20px;
}
.email_editorupload_pnl {
  padding: 0 20px 20px 20px;
  height: calc(100vh - 235px);
  overflow-y: auto;
}
.editor_icon_row {
  border: 1px solid #e4e4e4;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.editor_msgpnl .form-control {
  height: 250px;
  box-shadow: none;
  font-size: 16px;
  color: #2f3337;
  resize: none;
  padding: 20px 20px;
}

.editor_msgpnl .form-control:focus {
  border-color: #37aab2;
}
.email_upload_row {
  margin: 20px -5px 0;
  display: flex;
  flex-wrap: wrap;
}
.email_upload_bx {
  width: 25%;
  padding: 0 5px 10px;
}
.upload_bxInnr {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.email_uplod_info {
  display: flex;
  align-items: center;
  width: 75%;
}
.email_upload_icon {
  min-width: 25px;
  width: 25px;
  margin-right: 10px;
}
.email_uplod_cross a {
  display: block;
  line-height: 100%;
  color: #5f6368;
  margin-top: 5px;
}
.email_uplod_cross a i {
  font-size: 18px;
}
.email_upload_icon_text p {
  color: #2f3337;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bttm_send_pnl {
  padding: 15px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  height: 80px;
  display: flex;
  align-items: center;
}
.upload_sendOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.email_uploadBtn button {
  position: relative;
  background-color: transparent;
  cursor: pointer;
  color: #5f6368;
}
.dlete_sendBtnOuter {
  display: flex;
  align-items: center;
}
.chat_delete_btn {
  background-color: transparent;
  color: #5f6368;
  margin-right: 10px;
}

.chat_delete_btn:hover {
  color: #ff0000;
}

.email_share ul {
  display: flex;
  align-items: center;
}
.email_share ul li a {
  color: #5f6368;
  display: block;
  line-height: 100%;
}
.email_share ul li + li {
  margin-left: 10px;
}
.email_message_body h3 {
  font-size: 18px;
  color: #2f3337;
  margin-bottom: 25px;
  font-weight: 500;
}
.email_message_body p {
  font-size: 16px;
  color: #2f3337;
  margin-bottom: 25px;
}
.reply_msgRow {
  margin-top: 20px;
}
.reply_btn {
  display: flex;
  align-items: center;
  background-color: #e4e4e4;
  height: 44px;
  border-radius: 8px;
  padding: 0 15px;
  min-width: 105px;
  justify-content: center;
  color: #2f3337;
  font-size: 16px;
  font-weight: 400;
}
.email_editorupload_pnl.subjct_removePnl {
  height: calc(100vh - 165px);
}

.reply_msgRow .emailuser_outer {
  margin-bottom: 15px;
}
.email_upload_icon_text {
  width: 80%;
}
/* .reply_msgRow, .reply_sendbtnOuter{
    display: none;
} */

.reply_open .reply_msgRow {
  display: flex;
}
.reply_open .reply_sendbtnOuter {
  display: flex;
  width: 100%;
}
.reply_open .reply_btn_inr {
  display: none;
}

.owntext_row {
  margin-bottom: 20px;
}
.replied_msg_row {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  padding: 10px;
  text-align: left;
}
.replied_msg_row p {
  color: #e4e4e4;
}
.replied_msg_row span {
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}
.replied_msg_text {
  margin-top: 5px;
}
.reply_popup_area {
  position: absolute;
  left: 0;
  right: 0;
  background: #37aab2;
  bottom: 0;
  padding: 10px 70px 10px 10px;
}
.reply_popupmsg {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  border-left: 6px solid #fff;
  padding: 8px;
}
.reply_cross {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
}
.reply_cross a {
  display: inline-block;
  color: #fff;
}
.reply_popupmsg h5 {
  font-size: 13px;
  color: #fff;
  line-height: 23px;
}
.reply_popupmsg p {
  font-size: 14px;
  color: #fff;
}

.email_detailsmdl_Bx .mdl_hdng {
  border-radius: 0;
  background: #004254;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.email_detailsmdl_Bx.chat_modalbx .modal-content {
  background: #004254;
  border: 0;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0px;
  padding: 3px;
}
.email_detailsmdl_Bx .close {
  background: #fbbb21;
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #004254;
}

.email_details_body {
  background-color: #f4f4f4;
  padding: 20px;
}

.email_detailsmdl_Bx.chat_modalbx .modal-dialog {
  max-width: 600px;
}

.details_mdlHdr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px 35px;
}
.mdl_logo_right ul {
  display: flex;
  align-items: center;
}
.mdl_logo_right li {
  margin-right: 10px;
}
.mdl_logo_right li:last-child {
  margin-right: 0;
}
.mdl_logo_right li a {
  font-size: 12px;
  color: #6f7a7c;
  display: flex;
  align-items: center;
}
.mdl_logo_right li a i {
  font-size: 18px;
  margin-right: 5px;
}
.mdl_logo_right li:last-child a {
  color: #004254;
  font-size: 14px;
}
.email_details_bx {
  background-color: #fff;
  padding: 25px;
  margin: 0 25px;
}
.email_details_bx h3 {
  font-size: 18px;
  font-weight: 700;
  color: #004254;
  margin-bottom: 20px;
}

.email_details_bx p {
  font-size: 15px;
  color: #6f7a7c;
  margin-bottom: 30px;
}
.email_details_bx h4 {
  font-size: 16px;
  color: #37aab2;
  font-weight: 700;
}
.email_details_bx h4 span {
  color: #004254;
}
.goodluck_text {
  margin-top: 70px;
}
.goodluck_text h5 {
  font-size: 15px;
  color: #6f7a7c;
  line-height: 25px;
}
.goodluck_text h5 span {
  display: block;
  font-weight: 700;
  color: #004254;
}
.details_footer {
  padding: 25px 50px;
  text-align: center;
}
.mdl_footer_top {
  padding-bottom: 15px;
  border-bottom: 1px solid #c7c7c7;
}
.mdl_footer_top a {
  display: inline-block;
  font-size: 14px;
  color: #004254;
}
.mdl_footer_top a:hover {
  color: #fbbb21;
}
.mdl_footer_bttm {
  padding-top: 15px;
}

.mdl_footer_bttm p {
  font-size: 15px;
  color: #6f7a7c;
}
/* ========hamberger--menu--start======== */

.hamberger_menuInnr ul li a {
  padding: 20px;
  display: block;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  position: relative;
  border-bottom: 1px solid #043846;
}
.hamberger_menuInnr ul li a:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  /* background-color: #fbbb21; */
  transition: 0.5s;
  width: 0;
  z-index: -1;
}
.hamberger_menuInnr ul li a:hover {
  color: #fbbb21;
}
.hamberger_menuInnr ul li a:hover:after {
  width: 100%;
}

.serch_pnl {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  z-index: 9;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 12%);
}
.srch_innrFill {
  display: flex;
  align-items: center;
}
.serch_pnl .form-control {
  border: 0;
  box-shadow: none;
  border-radius: 0;
  font-size: 16px;
  color: #5f6368;
}
.srch_submitBtn {
  background: transparent;
  line-height: 100%;
  color: #5f6368;
}

/* ========hamberger--menu-end-======== */

/* =placeholder==glow-css---start */

.user_placeHoledr {
  display: flex;
  align-items: center;
}

.default_usr {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.default_usr_name {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.placeholder-sm {
  min-height: 4px;
  display: block;
}
.default_usr_name span + span {
  margin-left: 15px;
}
.grp_userHead {
  width: 30%;
}
.grp_userHead .default_usr_name span + span {
  display: block;
  margin: 10px 0 0;
}
.grp_userHead .default_usr_name {
  display: block;
}
.card-text span {
  margin-bottom: 10px;
}
.placeholder_row {
  padding: 15px 40px 30px;
}
.placeholder_row .card-text {
  margin-left: 45px;
}
.placeglow_chatouter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
}
.placeglow_chatouter .default_usr_name {
  display: block;
}
.placeglow_chatouter .user_placeHoledr {
  width: 70%;
}
.placeglow_chatouter .default_usr_name span + span {
  margin: 10px 0 0;
}
.email_placeholder_row {
  display: flex;
}
.email_placeholder_row .default_usr_name {
  display: block;
}

.email_placeholder_row .card-text {
  margin-left: 0;
}
.email_placeholder_row .default_usr_name span + span {
  margin: 10px 0 0;
}
.email_placeholder_row .user_placeHoledr {
  width: 100%;
}

/* =placeholder==glow-css---end */

/* emply-chat-css--start */

.empty_addlftPnl {
  padding: 20px;
  text-align: center;
  max-width: 265px;
  margin: 0 auto;
}
.add_textlftPnl {
  margin-bottom: 50px;
}
.add_textlftPnl h3 {
  font-size: 16px;
  color: #004254;
  font-weight: 700;
  margin-bottom: 15px;
}
.add_textlftPnl p {
  font-size: 15px;
  color: #6f7a7c;
}
.start_arwbtn {
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 40%;
  bottom: 25%;
}
.start_arwbtn a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border: 1px dashed #6f7a7c;
  font-size: 15px;
  color: #5f6368;
  height: 34px;
}
span.ditect_arrw {
  position: absolute;
  left: 55px;
  top: 100%;
  margin: 10px 0 0;
  display: block;
  width: 90px;
}
.welcome_screnBx {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.welcome_graphics {
  margin-bottom: 10px;
}
.welcome_innrPnl {
  text-align: center;
  padding: 20px;
}
.welcome_txtpnl h2 {
  color: #004254;
  font-size: 40px;
  margin-bottom: 15px;
}
.welcome_txtpnl p {
  font-size: 15px;
  color: #6f7a7c;
}

.tag_msg {
  margin-bottom: 10px;
}
.tag_msg a {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #004254;
  font-weight: 500;
}
.tag_msg a i {
  color: #fbbb21;
  font-size: 16px;
  margin-right: 5px;
}
.hamberger_menuPnl .pro_back_btn a {
  background-color: #fbbb21;
  color: #000000;
}
.profile_img.grp_profileImg {
  background-color: transparent;
  height: 50px;
  width: 50px;
}
.grp_profileImg .chatting_user {
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  background: transparent;
  overflow: visible;
}
.profile_img.grp_profileImg .group_usr {
  height: 25px;
  width: 25px;
}

/* emply-chat-css--end */

/* =========RESPONSIVE===========start */

@media (max-width: 1600px) {
  .chat_lftpnl {
    width: 25%;
  }
  .chat_rightpnl {
    width: 75%;
  }
  .chat_profileinfo_pnl,
  .hamberger_menuPnl {
    width: 25%;
  }
  .user_name h3 {
    font-size: 16px;
  }
  .user_name p {
    font-size: 12px;
  }
  .msg_text p,
  .own_msgArea p {
    font-size: 14px;
  }
}
@media (max-width: 1440px) {
  .user_name h3 {
    font-size: 16px;
  }
  .user_name p {
    font-size: 12px;
  }
}

@media (max-width: 1365px) {
  .chat_lftpnl {
    width: 28%;
  }
  .chat_rightpnl {
    width: 72%;
  }
}
@media (max-width: 1199px) {
  .add_textlftPnl {
    margin-bottom: 15px;
  }
  span.ditect_arrw {
    width: 65px;
  }
  .welcome_txtpnl h2 {
    font-size: 32px;
  }
}
@media (max-width: 991px) {
  .chat_lftpnl {
    width: 100%;
  }
  .chat_pnlOuter {
    flex-wrap: wrap;
  }
  .chat_rightpnl {
    width: 100%;
    position: absolute;
    top: 0;
    background-color: #fff;
    right: 0;
    transition: 0.5s;
    transform: translateX(100%);
    z-index: 100;
    bottom: 0;
  }
  .chat_usrPnl {
    height: calc(100vh - 125px);
  }
  .chat_profileinfo_pnl,
  .hamberger_menuPnl {
    width: 330px;
  }
  .chatmsg_open .chat_rightpnl {
    transform: translateX(0);
  }
  .list_back {
    display: block;
    margin-right: 10px;
  }
  .list_back a {
    color: #5f6368;
  }
  .chat_usrOuter {
    margin-right: auto;
  }
  .tags_row ul li a {
    font-size: 14px;
    height: 36px;
  }
  .chatusr_info {
    padding: 15px;
  }
  .tags_row {
    padding: 10px 15px;
  }
  .tags_row .dropdown-menu.show {
    min-width: 385px;
    padding: 15px;
  }
  .chat_sendPnl {
    padding: 15px;
  }
  .grp_chat_Innr {
    height: calc(100vh - 154px);
  }
  .profile_pnlArea {
    z-index: 100;
    width: 275px;
  }
  .tag_links ul li a {
    height: 36px;
    font-size: 14px;
    padding: 0 15px;
  }
  .chating_mdlPnl {
    height: calc(100vh - 154px);
  }
  .email_poupPnl {
    width: 100%;
  }
  .email_upload_bx {
    width: 33.33%;
  }
  .editor_msgpnl .form-control {
    height: 140px;
  }
  .email_poupPnl {
    z-index: 100;
  }
  .serch_pnl {
    top: 70px;
  }
  .hamberger_menuInnr ul li a {
    padding: 15px;
  }
  .start_arwbtn {
    left: 50%;
    transform: translateX(-50%);
  }
  span.ditect_arrw {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .own_msgRow .chat_msgright {
    max-width: 100%;
  }
  .chat_usrRow a {
    padding: 8px 15px;
  }
  .left_topPnl {
    padding: 10px 15px 10px;
  }
  .chat_usrPnl {
    height: calc(100vh - 103px);
  }
  .usr_srchRow .form-control {
    height: 40px;
    font-size: 14px;
  }
  .msg_text p,
  .own_msgArea p {
    font-size: 14px;
    line-height: 24px;
  }
  .msg_row {
    margin-bottom: 20px;
  }
  .msg_outer {
    padding: 15px 20px 20px;
  }
  .own_msgRow .chat_msgright {
    padding-right: 0;
  }
  .own_msgRow .chat_infoLft {
    margin-left: 15px;
  }
  .chat_msgright {
    padding-left: 15px;
  }
  .mail_text h4 {
    font-size: 14px;
  }
  .chat_cmntBx {
    bottom: 13%;
  }
  .email_poupPnl {
    width: 100%;
  }
  .email_cross a {
    height: 38px;
    width: 38px;
    min-width: 38px;
  }
  .email_cross a i {
    font-size: 20px;
  }
  .email_editorupload_pnl {
    height: calc(100vh - 234.1px);
  }
  .email_upload_icon_text {
    width: 72%;
  }
}
@media (max-width: 575px) {
  .chat_tablinks {
    padding: 10px;
  }
  .chat_tablinks ul li {
    min-width: 80px;
  }
  .chat_tablinks ul li:after {
    height: 1px;
    bottom: -10px;
  }

  .chating_mdlPnl {
    height: calc(100vh - 155px);
  }
  .grp_chat_Innr {
    height: calc(100vh - 155px);
  }
  .info_icons ul li + li {
    margin-left: 10px;
  }
  .chat_info_timeOuter h4 i {
    font-size: 16px;
  }
  .chat_info_timeOuter h4 {
    font-size: 14px;
  }
  .tags_links_items ul {
    overflow-x: auto;
  }
  .tags_links_items ul li a {
    white-space: nowrap;
  }
  .chat_profileinfo_pnl {
    width: 100%;
  }
  .chat_upload_pic {
    height: 155px;
  }
  .chat_upload_caption p {
    font-size: 13px;
  }
  .upload_footerArea {
    padding: 15px 0;
  }
  .upload_input_msg .form-control {
    height: 42px;
    font-size: 13px;
  }
  .upload_input_msg {
    margin-bottom: 10px;
  }
  .upload_addBtn,
  .upload_sendBtn {
    height: 40px;
    width: 40px;
  }
  .upload_addBtn i,
  .upload_sendBtn i {
    font-size: 16px;
  }
  .upload_innrscroll {
    height: calc(100vh - 182.2px);
  }
  .chat_input_fill .form-control {
    font-size: 13px;
  }
  .editor_msgpnl .form-control {
    padding: 15px;
    font-size: 14px;
  }
  .email_upload_bx {
    width: 50%;
  }
  .chat_modalbx .modal-dialog {
    margin: 30px auto;
    padding: 0 15px;
  }
  .hamberger_menuPnl {
    width: 100%;
  }
  .serch_pnl .form-control {
    font-size: 14px;
  }
  .srch_submitBtn i {
    font-size: 20px;
  }
  .reply_popup_area {
    padding: 10px 40px 10px 10px;
  }
  .reply_cross {
    right: 10px;
  }
  /* span.ditect_arrw{
        display: none;
    } */
  .welcome_txtpnl h2 {
    font-size: 26px;
  }
  span.ditect_arrw {
    left: 85px;
    margin: 0;
    width: 65px;
  }
  .start_arwbtn {
    bottom: 30%;
  }
}
@media (max-width: 480px) {
  .tags_row ul li a {
    font-size: 13px;
    padding: 0 10px;
  }
  .msg_row {
    flex-wrap: wrap;
  }
  .chat_infoLft {
    min-width: 100%;
    margin: 0 0 10px;
  }
  .own_msgRow .chat_infoLft {
    justify-content: flex-end;
    margin-top: 10px;
  }
  .chat_usrtext h3 {
    font-size: 16px;
  }
  .chat_usrtext h5 {
    font-size: 12px;
  }
  .chat_usrPic {
    height: 36px;
    width: 36px;
    min-width: 36px;
  }
  .profile_pnlArea {
    width: 100%;
    transform: translateX(100%);
  }
  .pro_back_btn {
    padding: 15px 15px 0;
  }
  .details_mdlHdr {
    padding: 15px 15px 20px;
  }
  .mdl_logo_right li:first-child {
    display: none;
  }
  .email_details_bx {
    padding: 15px;
    margin: 0 15px;
  }
  .goodluck_text {
    margin-top: 25px;
  }
  .email_details_bx h4 {
    font-size: 15px;
  }
  .email_details_bx p {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .details_footer {
    padding: 15px 15px;
  }
  .email_details_bx h3 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
@media (max-width: 420px) {
  .chat_usrtext h3 {
    font-size: 16px;
  }
  .chat_usrtext h5 {
    font-size: 12px;
  }
  .info_icons ul li + li {
    margin-left: 5px;
  }
  .info_icons ul li a i {
    font-size: 18px;
  }
  .chat_modalbx .chat_usrRow a {
    padding: 7px 15px;
  }
  .enter_group_name .form-control {
    font-size: 16px;
  }
  .group_srchfill .form-control {
    height: 40px;
    font-size: 14px;
  }
  .tags_row > ul {
    overflow-x: auto;
  }
  .tags_row > ul li a {
    white-space: nowrap;
  }
  .user_name h3 {
    font-size: 14px;
  }
  .msg_text p,
  .own_msgArea p {
    font-size: 13px;
    line-height: 22px;
  }
  .chat_usrtext h3 {
    font-size: 14px;
  }
  .group_btnOuter ul li a {
    height: 42px;
    font-size: 14px;
  }
  .group_btnOuter ul li a i {
    margin-right: 5px;
    font-size: 16px;
  }
  .profile_pnlArea {
    width: 100%;
  }
  .tags_row .dropdown-menu.show {
    min-width: 310px;
  }
  .chat_upload_pic {
    height: 110px;
  }
  .chat_uploadItem {
    padding: 5px;
  }
}

/*=======developmet-css==============================*/

/*
@media(max-width:991px){
    .chat_rightpnl{
        position: fixed;
    }
}
    
*/
