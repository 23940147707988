.own_msgRow .upload_picOuter {
    float: right;
    justify-content: flex-end;
}

.message_inner_row {
    margin-bottom: 25px;
}
.message_inner_row:last-child {
    -bottom: 0;
}

.upload_pic_item figure img {
    border-radius: 0;
    max-height: 100px;
}

.chat_profileinfo_pnl .pro_back_btn{
    justify-content: flex-start;
}

h3.font-weight-bold{
    font-weight: bold;
}

/* create group - input group name */
.enter_group_name .form-control{
    border: solid lightgrey 1px;
    padding: 20px;
}

@media(min-width:991px){
    .lft_cht_popup{
        bottom: 100px;
    }

}

@media(max-width:991px){
    .chat_rightpnl{
        position: fixed;
    }
}

@media(max-width:575px){
    .chating_mdlPnl {
        height: calc(100vh - 205px);
    }

}


  